<template>
  <div v-if="!reviewSubmitted" class="w-2/2 sm:w-1/2 m-auto my-12 px-10 sm:px-0">
    <h2 class="text-2xl lg:text-3xl font-bold mb-5">Your Review</h2>
    <div class="flex justify-between">
      <div class="flex items-center">
        <img :src="profileImage" :alt="profile.name" class="w-10 h-10 rounded-full object-cover flex-shrink-0 mr-4">
        <span class="text-lg font-bold">{{profile.name}}</span>
      </div>
      <div>
        <select v-if="profileType == 'guest'" class="form-select guestio-form-input" v-model="reviewBy">
            <option v-for="show in user.shows" :value="show.id" :key="`show-${show.id}`">
              {{ show.name }}
            </option>
          </select>
      </div>
    </div>

    <ValidationObserver ref="reviewForm" v-slot="{ handleSubmit }">
      <form action="" @submit.prevent="handleSubmit(saveReview)">
        <div class="flex flex-col mt-10">
          <label class="font-medium mb-3">Your rate</label>
          <ProfileRating editable :rating="form.number" activeColor="#B503D0" inactiveColor="#413F51" :starSize="25" @ratingSelected="ratingSelected"/>
        </div>
        <div class="mt-8">
          <div class="flex items-center justify-between">
            <label class="font-medium text-black">Your Review</label>
            <span class="text-gray-500">{{ form.comment.length }} / 300</span>
          </div>
          <ValidationProvider rules="required" name="Review" vid="comment" v-slot="{ errors }">
            <div class="mt-2">
              <textarea v-model="form.comment" maxlength="300"
                      class="w-full bg-gray-50 rounded-xl resize-none p-5 text-black text-lg focus:border focus:border-pink-500 shadow-2xl"
                      :class="{'border-red-500': errors.length}"/>
            </div>
            <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div class="mt-8 flex flex-col sm:flex-rew justify-center sm:justify-between items-center space-y-8 sm:space-y-0">
          <router-link :to="profileRoute" class="text-pink-500 flex items-center">
            <Arrow class="mr-3"/>
            Back to profile
          </router-link>
          <button :disabled="working" type="submit" class="btn" :class="{'opacity-50': working}">Save Review</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
  <div v-else class="w-2/2 sm:w-1/2 m-auto my-12 px-10 sm:px-0">
    <h2 class="text-2xl lg:text-3xl font-bold mb-5">Thank you!🙌</h2>
    <label class="mb-3">We are processing your review</label>
    <router-link :to="profileRoute" class="text-pink-500 flex items-center mt-10">
      <Arrow class="mr-3"/>
      Back to profile
    </router-link>

  </div>
</template>
<script>
import ProfileRating from "@/components/shared/ProfileRating";
import Arrow from "@/components/svgs/Arrow";
import api from '@/api'

export default {
  name: 'CreateReview',
  components: {ProfileRating, Arrow},
  props: {
    review: Object
  },

  data() {
      return {
        working: false,
        form: {
          number: 3.5,
          comment: '',
        },
        reviewSubmitted : false,
        reviewBy : null,
      }
    },

  created() {
    this.fetchProfile();
    if (this.profileType == 'guest') {
      this.reviewBy = this.user.shows[0].id;
    } else {
      this.reviewBy = this.user.guest.id;
    }
  },

  methods: {
    ratingSelected(number) {
      this.form.number = number
    },

    fetchProfile(){
      if (this.profileType == 'guest') {
        this.fetchGuest();
      } else {
        this.fetchShow();
      }
    },

    fetchGuest() {
      this.$store.dispatch('guest/fetchGuest', {
        id: this.$route.params.guestId
      }).then(() => {
        this.guardAgainstOwner()
      })
    },

    fetchShow() {
      this.$store.dispatch('shows/fetchShow', {
        id: this.$route.params.showId,
      }).then(() => {
        this.guardAgainstOwner()
      })
    },

    saveReview() {
      this.working = true

      if (this.profileType == 'guest') {
        return this.saveGuestReview()
      }

      return this.saveShowReview()
    },

    saveGuestReview() {
      this.form.show_id = this.reviewBy;
      api.post(`/guests/${this.$route.params.guestId}/reviews`, this.form)
        .then(() => {
          this.reviewSubmitted = true
        })
        .finally(() => this.working = false)
    },

    saveShowReview() {
      this.form.guest_id = this.reviewBy;
      api.post(`/shows/${this.$route.params.showId}/reviews`, this.form)
        .then(() => {
          this.reviewSubmitted = true
        })
        .finally(() => this.working = false)
    },

    guardAgainstOwner() {
      if (this.user.id != this.profile.user_id) {
        return
      }

      if (this.profileType == 'guest') {
        return this.$router.push({name: 'Guest', params: {guestId: this.profile.id}})
      }

      return this.$router.push({name: 'Show', params: {showId: this.profile.id}})
    }
  },

  computed: {
    profileType() {
      return this.$route.params.guestId ? 'guest' : 'show'
    },

    profile() {
      if (this.profileType == 'guest') {
        return this.$store.getters['guest/getGuest']
      } else {
        return this.$store.getters['shows/show']
      }
    },

    profileImage() {
      const defaultImage = require(`@/assets/images/test_image.png`);
      return this.profile.imageUrl ? this.profile.imageUrl : defaultImage
    },

    profileRoute() {
      if (this.profileType == 'guest') {
        return {name: 'Guest', params: {guestId: this.profile.id}}
      }

      return {name: 'Show', params: {showId: this.profile.id}}
    },

    user() {
      return this.$store.getters['auth/getUser']
    },
  },

  destroyed() {
    this.$store.commit('shows/clearShow')
    this.$store.commit('guest/clearGuest')
  }
}
</script>

<style scoped>
  .guestio-form-input {
    @apply pr-10 !important;
  }
</style>
